import {$host} from "../http/index";


export const createTagDB = async (tag) => {
    for (let i = 0; i < tag.length; i++) {
        await $host.post('api/tag', tag[i])
        //console.log(tag[i])
        // return data
    }
}

export const getTag = async (tag) => {
    const {data} = await $host.get('api/tag', tag)
    //console.log(data)
    return data
}


export const createTag = async (tag) => {
    //console.log(tag)
    const {data} = await $host.put('api/tag/newTag', tag)
    //console.log(data)
    return data
}



export const getTagOne = async (tag) => {
    const {data} = await $host.get('api/tag/one', tag)
    console.log("Вывод tag")
    console.log(tag)
    return data
}

export const updateTag = async (tag) => {
    const {data} = await $host.put('api/tag/updateTag', tag)
    //console.log(tag)
    return data
}


export const delTagPrice = async (tag) => {
    const {data} = await $host.put('api/tag/delTagPrice', tag)
    console.log(tag)
    return data
}
