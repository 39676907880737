import {$host} from "../http/index";


export const createCategoryDB = async (category) => {
    for (let i = 0; i < category.length; i++) {
        await $host.post('api/category', category[i])
        //console.log(category[i])
        // return data
    }
}

export const getCategory = async (category) => {
    const {data} = await $host.get('api/category', category)
    //console.log(data)
    return data
}


export const createCategory = async (category) => {
    console.log("category")
    console.log(category)
    const {data} = await $host.put('api/category/newCategory', category)
    console.log(data)
    return data
}



export const getCategoryOne = async (category) => {
    const {data} = await $host.get('api/category/one', category)
    //console.log("Вывод category")
    //console.log(category)
    return data
}

export const updateCategory = async (category) => {
    const {data} = await $host.put('api/category/updateCategory', category)
    //console.log(category)
    return data
}


export const delCategoryPrice = async (category) => {
    const {data} = await $host.put('api/category/delCategoryPrice', category)
    //console.log(category)
    return data
}
