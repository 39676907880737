import React, {useEffect, useState} from "react";
import {
    addComponent,
    //delComponent,
    getProduct,
    updateProduct,
    createProductOne, updateProductAll, delProduct,
    deleteALLTable,
    updateProductPhoto,
    creatNewProductNoComponent, getProductOneDB, updateProductOne, delFullDB, deleteFullDB,
} from "../http/productAPI"
import {
    createConstant,
    getConstant,
    updateConstant} from "../http/constantAPI"
import {createProDB} from '../controllers/product/createProductDB'
import {createConstDB} from "../controllers/component/createComponentDB";
import Modal from "../componets/modal/Model"
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
import Model from "../componets/modal/Model";
import Select, { components } from "react-select";
import Pagination from "@mui/material/Pagination";
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import {getCategory} from "../http/categoryAPI";
import {getTag} from "../http/tagAPI";
import {getDescription, getDescriptionOne} from "../http/descriptionAPI";



//import description from "./Description";
//import {Component} from "../../server/models/models";



const HomePage = () => {

    const [loaderContainer, setLoaderContainer] = useState("")
    const [loader, setLoader] = useState("")

    const loaderOne = () => {
        const l = "loader-container"
        const loader = "loader"
        setLoaderContainer(l)
        setLoader(loader)
    }


    useEffect(()=> {
        loaderOne()
        getConstant().then(data => {
            console.log(data)
            setConstant(data)})
            .then(()=>{
                getProduct().then(data => {
                        if (product.length === 0) {
                            setProduct(data)
                        }
                    }
                ).then(()=>{
                    setLoaderContainer("")
                    setLoader("")
                    getCategory().then(data =>{
                        setCategory(data)
                    }).then(()=>{
                        getTag().then(data => {
                            setTags(data)
                        }).then(()=>{
                            getDescription().then(data => {
                                setDescriptionsOne(data)
                            })
                        })
                    })
                    console.log("product")
                    console.log(product)
                    console.log("category")
                    console.log(category)
                })
            })},[])

    useEffect(()=> {
        getDescription().then(data => {
            setDescription(data)
        }).then()},[])

    useEffect(()=> {
        getCategory().then(data => {
            setCategory(data)
        }).then()},[])


    const [description, setDescription] = useState([]);

    const [product, setProduct] = useState([]);
    const [constant, setConstant] = useState([]);
    const [input, setInput] = useState("");
    const [inputComponent, setInputComponent] = useState("")
    const [searchName, setSearchName] = useState("")
    const [searchNameComponent,setSearchNameComponent] = useState("")
    const [searchNameProduct, setSearchNameProduct] = useState("")
    const [searchNameProductModal, setSearchNameProductModal] = useState("")
    const [componentModalResult, setComponentModalResult] = useState([])
    const [isOpen, setIsOpen] = useState(true)
    const [modalActive, setModalActive] = useState(false)
    const [modalActiveConstant, setModalActiveConstant] = useState(false)
    const [modalActiveNewComponent, setModalActiveNewComponent] = useState(false)
    const [modalActiveNewComponentIsConstant,setModalActiveNewComponentIsConstant] = useState(false)

    const [modalActiveEditProductName,setModalActiveEditProductName] = useState(false)
    const [modalActiveEditProductCategoryMain,setModalActiveEditProductCategoryMain] = useState(false)
    const [modalActiveEditProductCategory,setModalActiveEditProductCategory] = useState(false)
    const [modalActiveEditProductTags,setModalActiveEditProductTags] = useState(false)
    const [modalActiveEditProductDescriptions,setModalActiveEditProductDescriptions] = useState(false)
    const [modalActiveEditProductComponents,setModalActiveEditProductComponents] = useState(false)


    const [modalEditPrice, setModalEditPrice] = useState(false)
    const [modelCreatProduct, setModelCreatProduct] = useState(false)
    const [modalAddDescriptionAndMainCategories, setModalAddDescriptionAndMainCategories] = useState(false)
    const [modalActiveEditProduct, setModalActiveEditProduct] = useState(false)
    const [productIdWoo,setProductIdWoo] = useState(0)
    const [componentNameInput, setComponentNameInput] = useState("")
    const [indexComponent, setIndexComponent] = useState(0)
    const [componentCountInput, setComponentCountInput] = useState(0)
    const [productNameInput, setProductNameInput] = useState("")
    const [selectedCategories, setSelectedCategories] = useState(15);
    const [selectedCategoriesMain,setSelectedCategoriesMain] = useState([]);
    const [selectedCategoriesDop, setSelectedCategoriesDop] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [productDescription, setProductDescription] = useState(0)
    const [component, setComponent] = useState([])
    //const [searchNameFilter, setSearchNameFilter] = useState("")

    const [filter, setFilter] = useState(false)
    const [numDescendingState, setNumDescendingState] = useState(false)
    const [page, setPage] = React.useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [countPage, setCountPage] = useState(10);
    const [inputSelect, setInputSelect] = useState("Категория 1")
    const [descriptionsOne,setDescriptionsOne] = useState([])

    let [productEditOne,setProductEditOne] = useState([])

    const [id, setId] = useState("")
    const [idWoo, setIdWoo] = useState("")
    const [name, setName]= useState("")
    const [imgUrl, setImgUrl]= useState("")
    const [componentsMain, setComponentsMain]= useState([])
    const [categoryMain, setCategoryMain]= useState("")
    const [category, setCategory]= useState([])
    const [tags, setTags]= useState([])






    const strAscending = [...product].sort((a, b) => a.name.localeCompare(b.name))
    const numAscending = [...product].sort((a, b) => a.id - b.id);
    const numDescending = [...product].sort((a, b) => b.id - a.id);

    const filterProduct =
        filter ?
            strAscending.filter(product => {
                return product.name.toLowerCase().includes(searchNameProduct.toLowerCase())}):
            numDescendingState ?
                numAscending.filter(product => {
                    return product.name.toLowerCase().includes(searchNameProduct.toLowerCase())}):
                numDescending.filter(product => {
                    return product.name.toLowerCase().includes(searchNameProduct.toLowerCase())})

    const filterName = constant.filter(constant => {
        return constant.name.toLowerCase().includes(searchName.toLowerCase())
    })
    const filterNameComponent = constant.filter(constant => {
        return constant.name.toLowerCase().includes(searchNameComponent.toLowerCase())
    })

    const filterNameProduct = product.filter(product => {
        return product.name.toLowerCase().includes(searchName.toLowerCase())
    })


    const api = new WooCommerceRestApi({
        url: "https://dari-shary.ru",
        consumerKey: "ck_43cc19745fb8acb7a740e9723e8e85047bf7e960",
        consumerSecret: "cs_9a9b6f01e72b2fb9b7c0fab1ea390c3e5b218a64",
        version: "wc/v3"
    });






    const InputOption = ({
                             getStyles,
                             Icon,
                             isDisabled,
                             isFocused,
                             isSelected,
                             children,
                             innerProps,
                             ...rest
                         }) => {
        const [isActive, setIsActive] = useState(false);
        const onMouseDown = () => setIsActive(true);
        const onMouseUp = () => setIsActive(false);
        const onMouseLeave = () => setIsActive(false);


        let bg = "transparent";
        if (isFocused) bg = "#eee";
        if (isActive) bg = "#B2D4FF";



        const props = {
            ...innerProps,
            onMouseDown,
            onMouseUp,
            onMouseLeave,
        };

        return (
            <components.Option
                {...rest}
                isDisabled={isDisabled}
                isFocused={isFocused}
                isSelected={isSelected}
                getStyles={getStyles}
                innerProps={props}
            >
                <input type="checkbox" checked={isSelected} />
                {children}
            </components.Option>
        );
    };





    // useEffect(()=> {
    //     getConstant().then(data => {
    //         setConstant(data)
    //     }).then()},[])



    // useEffect(()=> {
    //     getProduct().then(data => {
    //         setProduct(data)
    //     }).then()},[])

    // useEffect(()=> {
    //     createPriceComponent()
    //    },[product])

    // const getProd = () => {
    //         getProduct().then(data=>{
    //         setProduct(data)
    //     })
    // }

    // const delIngredientOne = (idWoo, componentName, indexComponent ) => {
    //     let productIdWoo = idWoo
    //     let searchName = componentName
    //     const resultComponent = constant.find(({ name }) => name === searchName);
    //     const resultProduct = product.find(({ idWoo }) => idWoo === productIdWoo);
    //     delete resultComponent.id
    //     const result  = resultProduct.component.filter(({ name }) => name !== searchName);
    //     let sum = 0;
    //     const sumProduct = () => {
    //         for (let i = 0; i < result.length; i++) {
    //             sum += result[i].sumComponent
    //         }
    //     }
    //     sumProduct()
    //     console.log("result")
    //     console.log(result)
    //     console.log("idWoo")
    //     console.log(idWoo)
    //     console.log("productIdWoo")
    //     console.log(productIdWoo)
    //     console.log("resultProduct")
    //     console.log(resultProduct)
    //     console.log("resultComponent")
    //     console.log(resultComponent)
    //     setProduct(prevState =>
    //         prevState.map(item =>
    //             item.idWoo === productIdWoo
    //                 ? { ...item,
    //                     component: result,
    //                     price: sum
    //                 }
    //                 : item))
    //
    //     delComponent({productIdWoo, componentName, indexComponent}).then(r => console.log(r))
    //
    //     const regular_price = sum
    //     const price = {
    //         regular_price: String(regular_price),
    //     };
    //     api.put(`products/${productIdWoo}`, price)
    //         .then((response) => {
    //             console.log(response.data)
    //             // console.log(response.data)
    //         })
    //         .catch((error) => {
    //             console.log(error.response.data);
    //         });
    // }




    const addComponentInBouquet = (input, searchName, idWoo, productPrice) => {
        const resultComponent = constant.find(({ name }) => name === searchName);
        const resultProduct = product.find(({ idWoo }) => idWoo === productIdWoo);
        delete resultComponent.id
        resultComponent.count = Number(input)
        resultProduct.component.push(resultComponent)
        resultComponent.sumComponent = 0
        setProduct(prevState =>
            prevState.map(item =>
                item.idWoo === idWoo
                    ? { ...item,
                        component: resultProduct}
                    : item))
        console.log(`resultProduct${resultProduct}`)
        console.log(idWoo)
        console.log(resultComponent)
        console.log(input)
        console.log(searchName)
        createPriceComponent()
        addComponent({productIdWoo, input, searchName, productPrice}).then(r=>console.log(r))
        setModalActiveNewComponent(false)
        setInput("")
        setSearchName("")
        const regular_price = resultProduct.price
        const price = {
            regular_price: String(regular_price),
        };
        const api = new WooCommerceRestApi({
            url: "https://testdostavka.kami-flow.ru",
            consumerKey: "ck_db6a9e0b0ba44f769bbae2acedfcd836d7e47ba0",
            consumerSecret: "cs_b412b3073655abbdc5f97c32244950b4760a3e1d",
            version: "wc/v3",
        });
        api.put(`products/${productIdWoo}`, price)
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error.response.data);
            });
    }
    const addConstant = (count, name) => {
        setInput("")
        setSearchName("")
        setModalActiveConstant(false)
        createConstant({count, name}).then(r => console.log(r))
        console.log("Open")
        console.log(name)
        console.log(count)
    }


    const itemClickHandler = (e) => {
        setSearchName(e.target.textContent)
        setIsOpen(false)
    }

    const itemClickHandlerComponent = (e) => {
        console.log("isOpen Click")
        setSearchNameComponent(e.target.textContent)
        setIsOpen(false)
        console.log("isOpen")
        console.log(isOpen)
    }
    const itemClickHandler1 = (name) => {
        setSearchName(name)
        setIsOpen(false)
    }
    const inputClickHeader = () => {
        setIsOpen(true)
    }

    const inputClickHeaderComponent = () => {
        setIsOpen(true)

    }
    // const modalActiveInput = (idWoo,productName,componentName,componentCount,componentId) => {
    //     console.log(`вызозов функции modalActiveInput`)
    //     setProductNameInput(productName)
    //     setProductIdWoo(idWoo)
    //     setComponentNameInput(componentName)
    //     setComponentCountInput(componentCount)
    //     setIndexComponent(componentId)
    //     console.log(`idWoo: ${idWoo}`)
    //     console.log(`productName: ${productName}`)
    //     console.log(`componentName: ${componentName}`)
    //     console.log(`componentCount: ${componentCount}`)
    // }
    const editIngredientOne = (productId, componentName, count, componentId) => {
        const countInput = Number(count)
        const resultProduct = product.find(({ idWoo }) => idWoo === productId);
        resultProduct.component[componentId].count = input

        setProduct(prevState =>
            prevState.map(item =>
                item.id === resultProduct.id
                    ? { ...item,
                        resultProduct}
                    : item))
        updateProduct({productId, componentName, countInput}).then(r => console.log(r))
        setInput("")
        setSearchName("")
        setModalActive(false)
        createPriceComponent()
        const regular_price = resultProduct.price
        const price = {
            regular_price: String(regular_price),
        };
        const api = new WooCommerceRestApi({
            url: "https://testdostavka.kami-flow.ru",
            consumerKey: "ck_db6a9e0b0ba44f769bbae2acedfcd836d7e47ba0",
            consumerSecret: "cs_b412b3073655abbdc5f97c32244950b4760a3e1d",
            version: "wc/v3",
        });
        api.put(`products/${productIdWoo}`, price)
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error.response.data);
            });

    }
    const createPriceComponent = () => {
        const resultProduct = [...product]
        for (let i = 0; i < resultProduct.length; i++) {
            for (let j = 0; j < resultProduct[i].component.length; j++) {
                for ( let k = 0; k < constant.length; k++) {
                    if (resultProduct[i].component[j].name === constant[k].name){
                        resultProduct[i].component[j].price = constant[k].price
                    }
                }
            }
        }
        console.log("resultProduct")
        console.log(resultProduct)
        setProduct(resultProduct)
        createSum()
    }
    // const createPriceComponentWait = () => {
    //     setTimeout(createPriceComponent, 100);
    // }
    const createSum = () => {
        const result = [...product]
        for (let i = 0; i < result.length; i++) {
            result[i].price = 0
            for (let j = 0; j < result[i].component.length; j++) {
                result[i].component[j].sumComponent = 0
                for ( let k = 0; k < constant.length; k++) {
                    if (result[i].component[j].name === constant[k].name){
                        result[i].component[j].sumComponent = result[i].component[j].price * result[i].component[j].count
                        result[i].price += result[i].component[j].sumComponent}
                }
            }
        }
        setProduct(result)
        console.log(result)
        const resultDB = [...result]
        for (let i = 0; i < resultDB.length; i++) {
            updateProductAll1(resultDB[i].idWoo, resultDB[i].price)
        }
    }
    const updateProductAll1 = (idWoo, price) => {
        updateProductAll({idWoo, price}).then()
    }
    // const goModalConstant = () => {
    //     setModalActiveConstant(true)
    // }
    const goModalNewComponent = (idWoo) => {
        //setComponentPrice(componentPrice)
        setModalActiveNewComponent(true)
        setProductIdWoo(idWoo)
    }
    // const goModalEditPrice = () => {
    //     setModalEditPrice(true)
    // }
    const editIngredientAll = (input, searchName ) => {
        console.log(input)
        console.log(searchName)
        setInput("")
        setSearchName("")
        setModalEditPrice(false)
        updateConstant({input, searchName}).then(()=> {
        })
        createPriceComponent()
        console.log(product)

        //window.location.reload()
    }
    const sentWoo = () => {
        let result = 0;
        for(let i = 598 ; i < 601; i++) {
            const resultProduct = product.find(({ id }) => id === i);
            result = resultProduct.price
            const price = {
                regular_price: String(result),
            };
            api.put(`products/${resultProduct.idWoo}`, price)
                .then((response) => {
                    console.log(response.data)
                })
                .catch((error) => {
                    console.log(error.response.data);
                });
        }
    }
    const sentWooWait = () => {
        setTimeout(sentWoo, 100);
    }
    const creatNewProduct = () => {
        setModelCreatProduct(true)
    };
    const saveNewProduct = (
        searchNameProductModal,
        selectedCategoriesMain,
        selectedCategories,
        selectedOptions,
        componentModalResult,
        inputSelect,
        ) => {
        activeLoaderMain()
        console.log("selectedCategoriesMain")
        console.log(selectedCategoriesMain)
        let descriptionsCreateWoo;
        for (let i = 0; i < descriptionsOne.length; i++) {
            if (descriptionsOne[i].title === inputSelect) {
                descriptionsCreateWoo = descriptionsOne[i].name
            }
        }
        console.log(descriptionsCreateWoo)

        let componentDescriptions = "";
        for (let i = 0; i < componentModalResult.length; i++) {
            componentDescriptions += `${componentModalResult[i].name} - ${componentModalResult[i].count} шт.<br>`
        }
        console.log(componentDescriptions)
        const resComponent = []
        for (let i = 0; i < selectedCategories.length; i++) {
            const result = selectedCategories[i].idWooCategory
            const res = {id:result}
            resComponent.push(res)
        }

        const resTags = []
        for (let i = 0; i < selectedOptions.length; i++) {
            const result = selectedOptions[i].idWooTag
            const res = {id:result}
            resTags.push(res)
        }

        let numReserve = []
        while (numReserve.length < 12) {
            let randomNumber = Math.ceil(Math.random() * 1000000);
            let found = false;
            for (let i = 0; i < numReserve.length; i++) {
                if (numReserve[i] === randomNumber){
                    found = true;
                    break;
                }
            }
            if (!found) { numReserve[numReserve.length]=randomNumber; }
        }
        let price = 0;
        for (let j = 0; j < constant.length; j++) {
            for (let i = 0; i < componentModalResult.length; i++) {
                if (componentModalResult[i].name === constant[j].name) {
                    price += componentModalResult[i].count * constant[j].price
                }
            }
        }
        const data = {
            name: searchNameProductModal,
            type: "simple",
            regular_price: String(price),
            sku: String(numReserve[0]),
            tags: resTags,
            categories: resComponent,
            description:`<p style="text-align: left; margin-bottom: 0em">Cостав:</p><br><p style="text-align: left; margin-bottom: 0em">${componentDescriptions}</p><br>${descriptionsCreateWoo}`,
            meta_data: [
                    {
                        "key": "_yoast_wpseo_primary_product_cat",
                        "value": `${selectedCategoriesMain[0].idWooCategory}`
                    }
                ]

        };
        const api = new WooCommerceRestApi({
            url: "https://dari-shary.ru",
            consumerKey: "ck_43cc19745fb8acb7a740e9723e8e85047bf7e960",
            consumerSecret: "cs_9a9b6f01e72b2fb9b7c0fab1ea390c3e5b218a64",
            version: "wc/v3"
        });
        api.post("products", data)
            .then((response) => {
                console.log(response.data)
                const result = response.data.id
                const res = {
                    name: searchNameProductModal,
                    selectedCategoriesMain: selectedCategoriesMain,
                    selectedCategories: selectedCategories,
                    selectedOptions: selectedOptions,
                    componentModalResult:componentModalResult,
                    inputSelect:inputSelect,
                    idWoo: result,
                    vendorСode: response.data.sku,
                    url: response.data.permalink
                }
                createProductOne(res).then((response)=>{
                    console.log(response.data)
                })
                setTimeout(window.location.reload(), 30000);
            })
            .catch((error) => {
                console.log(error.response.data);
            });

    }

    const time = () => {
        const api = new WooCommerceRestApi({
            url: "https://testdostavka.kami-flow.ru",
            consumerKey: "ck_db6a9e0b0ba44f769bbae2acedfcd836d7e47ba0",
            consumerSecret: "cs_b412b3073655abbdc5f97c32244950b4760a3e1d",
            version: "wc/v3"
        });

            api.get("products/20384")
            .then((response) => {
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error.response.data);
            });
    }
    const addDescriptionAndMainCategoriesModal = (productIdWoo, component) => {
        setComponent(component)
        setProductIdWoo(productIdWoo)
    }

    // const addDescriptionAndMainCategories = () => {
    //     if (productDescription === 0) {
    //         console.log(selectedCategories)
    //         for (let i = 0; i < component.length; i++) {
    //             result += `${component[i].name} - ${component[i].count} шт <br />`
    //         }
    //         let end = result + textNo
    //         const data = {
    //             description:String(end),
    //             meta_data: [
    //                 {
    //                     "key": "_yoast_wpseo_primary_product_cat",
    //                     "value": `${selectedCategories}`
    //                 }
    //             ]
    //         };
    //         api.post(`products/${productIdWoo}`, data)
    //             .then((response) => {
    //                 console.log(response.data)
    //
    //             })
    //             .catch((error) => {
    //                 console.log(error.response.data);
    //             });
    //         console.log("простой букет")
    //     }
    //
    //     if (productDescription === 1) {
    //         console.log(selectedCategories)
    //         for (let i = 0; i < component.length; i++) {
    //             result += `${component[i].name} - ${component[i].count} шт <br />`
    //         }
    //         let end = textOne + result + textNo
    //         const data = {
    //             description:String(end),
    //             meta_data: [
    //                 {
    //                     "key": "_yoast_wpseo_primary_product_cat",
    //                     "value": `${selectedCategories}`
    //                 }
    //             ]
    //
    //         };
    //         api.post(`products/${productIdWoo}`, data)
    //             .then((response) => {
    //                 console.log(response.data)
    //
    //             })
    //             .catch((error) => {
    //                 console.log(error.response.data);
    //             });
    //         console.log("Съедобный букет")
    //     }
    //
    //     if (productDescription === 2) {
    //         console.log(selectedCategories)
    //         console.log("productDescription")
    //         console.log(productDescription)
    //         for (let i = 0; i < component.length; i++) {
    //             result += `${component[i].name} - ${component[i].count} шт <br />`
    //         }
    //         let end = textOne + result + textIndex + textNo
    //         const data = {
    //             description:String(end),
    //             meta_data: [
    //                 {
    //                     "key": "_yoast_wpseo_primary_product_cat",
    //                     "value": `${selectedCategories}`
    //                 }
    //             ]
    //         };
    //         api.post(`products/${productIdWoo}`, data)
    //             .then((response) => {
    //                 console.log(response.data)
    //
    //             })
    //             .catch((error) => {
    //                 console.log(error.response.data);
    //             });
    //         console.log("Алкогольный Букет")
    //     }
    //     setModalAddDescriptionAndMainCategories(false)
    // }

    const activeLoaderMain = () => {
        const l = "loader-container"
        const loader = "loader"
        setLoaderContainer(l)
        setLoader(loader)
        setTimeout(() => {
            setLoaderContainer("")
            setLoader("")
        }, 10000);
    }
    const activeLoaderMainModal = () => {
        const l = "loader-container"
        const loader = "loader"
        setLoaderContainer(l)
        setLoader(loader)
    }
    const activeLoaderMainModalOff = () => {
        setLoaderContainer("")
        setLoader("")
    }

    const clickCount = () => {
        if (numDescendingState === false) {
            setNumDescendingState(true)
        }
        if (numDescendingState === true) {
            setNumDescendingState(false)
        }
    }
    const clickCountWait = () => {
        setTimeout(clickCount, 100);
    }


    const deleteProduct = (productIdWoo) => {
        const result  = product.filter(({ idWoo }) => idWoo !== productIdWoo);
        delProduct({productIdWoo}).then()
        setProduct(result)
        console.log(result)
        api.delete(`products/${productIdWoo}`)
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error.response.data);
            });
    }
    // useEffect(()=> {
    //     console.log(product)
    //     if (product.length === 0) {
    //         getProd();
    //         activeLoaderMain()
    //         createPriceComponentWait();
    //         activeLoaderMain()
    //         }
    //     }
    // )

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        console.log(value)
        let resultStartIndex
        let resultCountPage
        if (value === 1) {
            resultStartIndex = 0
            resultCountPage = 10
        }
        if (value > 1) {
            resultStartIndex = (value * 10) - 10
            resultCountPage = value * 10
        }

        setStartIndex(resultStartIndex)
        setCountPage(resultCountPage)
        setPage(value);
    };
    const citrus = filterProduct.slice(startIndex, countPage)

    const deleteAll = () => {
        console.log("Click front")
        deleteALLTable().then()
    }
    const editProductCard = (product,id,idWoo,name,categoryMain,imgUrl,components,categories, tags) => {

        console.log(productEditOne)
        setModalActiveEditProduct(true)
        setId(id)
        setIdWoo(idWoo)
        setName(name)
        setCategoryMain(categoryMain)
        setImgUrl(imgUrl)
        setComponentsMain(components)
        // getCategory((data)=>{
        //     setCategory(data)
        //     console.log("data")
        //     console.log(data)
        // }).then()

        //setCategory(categories)
        // setTags(tags)
        // console.log("categories")
        // console.log(category)
    }
    // const componentResult = (res) => {
    //     if(componentModalResult !== []) {
    //         const result = [...componentModalResult, res]
    //         setComponentModalResult(result)
    //     }
    // }
    const photoSend = (idWoo) => {
        activeLoaderMain()
        api.get(`products/${idWoo}`)
            .then((response) => {
                const imgUrl = response.data.images[0].src
                console.log(response.data.images[0].src);
                const result = {
                    idWoo:idWoo,
                    imgUrl:imgUrl,
                }
                activeLoaderMain()
                updateProductPhoto(result).then()
            })
            .catch((error) => {
                console.log(error.response.data);
            });
    }

    const createProDBWoo = () => {
        console.log("click")
        api.get(`products?per_page=100`)
            .then((response) => {
               creatNewProductNoComponent(response.data).then()
            })
            .catch((error) => {
                console.log(error.response.data);
            });
    }

    const modalEditProductSaveName = (newName, productNameMain) => {
        if (productNameMain !== newName) {
            productEditOne[0].name = newName
            console.log(productEditOne)
            //setProductEditOne()
            console.log(productNameMain)
            console.log(newName)
        }
    }
    const modalActiveEditProductCategoryMainAndTage = () => {
        if (selectedCategoriesMain.length === 1) {
            productEditOne[0].categoryMain = selectedCategoriesMain[0].label
        }

        if (selectedCategories.length !== undefined && selectedCategories.length !== 0) {
            const categoriesAll = productEditOne[0].categories
            const clone = Object.assign([], categoriesAll);
            clone.length = 0
            clone.push(selectedCategories)
            console.log("clone")
            console.log(clone)
            setProductEditOne(prevState =>
                prevState.map(item =>
                    item
                        ? { ...item,
                            categories: clone[0],
                        }
                        : item))

        }
        if (selectedOptions !== undefined && selectedOptions.length !== 0) {
            const tagsAll = productEditOne[0].tags
            const clone = Object.assign([], tagsAll);
            clone.length = 0
            clone.push(selectedOptions)
            console.log("clone")
            console.log(clone)
            setProductEditOne(prevState =>
                prevState.map(item =>
                    item
                        ? { ...item,
                            tags: clone[0],
                        }
                        : item))

        }
    }
    const modalEditProductSaveDes = () => {
        const result  = description.filter(({ title }) => title === inputSelect);
        productEditOne[0].descriptions = result
    }

    const modalEditProductDelComponent = (nameComponent) => {
        const result  =  productEditOne[0].components.filter(({ name }) => name !== nameComponent);
        console.log(result)
        let price = 0;
        for (let i = 0; i < result.length; i++) {
            const resultSum = result[i].count * result[i].price
            console.log(resultSum)
            price += resultSum
        }
        console.log(price)
        setProductEditOne(prevState =>
            prevState.map(item =>
                item
                    ? { ...item,
                        components: result,
                        price: price,
                        }
                    : item))

    }

    const modalAddComponent = (input, searchName) => {
        const result  =  constant.filter(({ name }) => name === searchName);
        console.log("searchName")
        console.log(searchName)
        console.log("result")
        console.log(result)
        const resultComponent = {
            id: result[0].id,
            name: result[0].name,
            price: result[0].price,
            count: Number(input),
            sum: input * result[0].price,
        }
        console.log("resultComponent")
        console.log(resultComponent)
        const componentAll = productEditOne[0].components
        const clone = Object.assign([], componentAll);
        clone.push(resultComponent)
        console.log("clone")
        console.log(clone)
        setProductEditOne(prevState =>
            prevState.map(item =>
                item
                    ? { ...item,
                        components: clone,
                    }
                    : item))
        console.log("productEditOne")
        console.log(productEditOne)
        let price = 0;
        for (let i = 0; i < clone.length; i++) {
            const resultSum = clone[i].count * clone[i].price
            console.log(resultSum)
            price += resultSum
        }
        setProductEditOne(prevState =>
            prevState.map(item =>
                item
                    ? { ...item,
                        price: price,
                    }
                    : item))
    }
    const saveEditProduct = (productEditOne) => {
        setModalActiveEditProduct(false)
        // console.log(productEditOne)
        // console.log("productEditOne.name")
        // console.log(productEditOne[0].name)
        // console.log("productEditOne.price")
        // console.log(productEditOne[0].price)
        // console.log("productEditOne.tags")
        // console.log(productEditOne[0].tags)
        // console.log("productEditOne.categories")
        // console.log(productEditOne[0].categories)
        // console.log("description")
        // console.log(productEditOne[0].descriptions[0].name)
        // console.log("productEditOne.categoryMain")
        // console.log(productEditOne[0].categoryMain)
        // console.log("productEditOne.categories")
        // console.log(productEditOne[0].categories)
        // console.log("productEditOne.components")
        // console.log(productEditOne[0].components)

        let componentDescriptions = "";
        for (let i = 0; i < productEditOne[0].components.length; i++) {
            componentDescriptions += `${productEditOne[0].components[i].name} - ${productEditOne[0].components[i].count} шт.<br>`
        }
        const resTags = []
        for (let i = 0; i < productEditOne[0].tags.length; i++) {
            const result = productEditOne[0].tags[i].idWooTag
            const res = {id:result}
            resTags.push(res)
        }
        const resComponents = []
        for (let i = 0; i < productEditOne[0].components.length; i++) {
            const result = productEditOne[0].components[i].name
            const resultCount = productEditOne[0].components[i].count
            const resultPrice = productEditOne[0].components[i].price
            const res = {
                name: result,
                count: resultCount,
                price: resultPrice,
            }
            resComponents.push(res)
        }

        const resCategories = []
        for (let i = 0; i < productEditOne[0].categories.length; i++) {
            const result = productEditOne[0].categories[i].idWooCategory
            const res = {id:result}
            resCategories.push(res)
        }
        const result  =  category.filter(({ label }) => label === productEditOne[0].categoryMain);
        console.log("result")
        console.log(result.idWooCategory)
        const data = {
            name: productEditOne[0].name,
            regular_price: String(productEditOne[0].price),
            tags: resTags,
            categories: resCategories,
            description:`<p style="text-align: left; margin-bottom: 0em">Cостав:</p><br><p style="text-align: left; margin-bottom: 0em">${componentDescriptions}</p><br>${productEditOne[0].descriptions[0].name}`,
            meta_data: [
                {
                    "key": "_yoast_wpseo_primary_product_cat",
                    "value": `${result[0].idWooCategory}`
                }
            ]
        }

        api.put(`products/${productEditOne[0].idWoo}`, data)
            .then((response) => {
                const result = {
                    idWoo: productEditOne[0].idWoo,
                    name: productEditOne[0].name,
                    price: productEditOne[0].price,
                    tags: resTags,
                    categories: resCategories,
                    components: resComponents,
                    imgUrl: productEditOne[0].imgUrl,
                    url: productEditOne[0].url,
                    categoryMain: productEditOne[0].categoryMain,
                    descriptions: productEditOne[0].descriptions[0].title,
                }
                updateProductOne(result).then()
                console.log(response.data)
                const resultModalLoad = () => {
                    activeLoaderMainModalOff()
                    window.location.reload()
                }
                setTimeout(resultModalLoad(), 10000);
            })
            .catch((error) => {
                console.log(error.response.data);
            });
        console.log("productEditOne")
        console.log(productEditOne)

    }
    const delFullDB = () => {
        deleteFullDB().then()
    }
    return (
        <>
            <div className={loaderContainer}>
                <div className={loader}></div>
            </div>
            <Stack spacing={2}>
                <Typography>
                    <div className="main" >
                        <Modal active={modalActiveEditProduct} setActive={setModalActiveEditProduct}>
                            <div className="titleNewProductModal">Редактирование продукта</div>
                            {productEditOne.map((productEdit, indexProductEdit)=> {
                               return (
                                   <div>
                                       <img className="imgCardModal" src={productEdit.imgUrl}   alt="логотип" />
                                       <div className="textMainCardTitle">Название:</div>
                                       <div className="textMainCard">{productEdit.name}</div>
                                       <button onClick={()=>{setModalActiveEditProductName(true)}}>ред. имя</button>
                                       <div className="textMainCardTitle">Основная категория:</div>
                                       <div className="textMainCard">{productEdit.categoryMain}</div>
                                       <button onClick={()=>{setModalActiveEditProductCategoryMain(true)}}>ред. категории и метки</button>
                                       <div className="textMainCardTitle">Дополнительные категории:</div>
                                       <div>{productEdit.categories.map((category, indexCategory)=>{
                                           return (
                                               <div className="textMainCard">
                                                   {category.label}
                                               </div>
                                           )
                                       })}</div>
                                       {/*<button onClick={()=>{setModalActiveEditProductCategory(true)}}>ред. доп. категории</button>*/}
                                       <div className="textMainCardTitle">Метки:</div>
                                       <div>{productEdit.tags.map((tags, indexTags)=> {
                                           return (
                                               <div className="textMainCard">
                                                   {tags.label}
                                               </div>
                                           )
                                       })}</div>
                                       {/*<button onClick={()=>{setModalActiveEditProductTags(true)}}>ред. метки</button>*/}
                                       <div className="textMainCardTitle">Описание:</div>
                                       <div>{productEdit.descriptions.map((descriptions, indexTags)=> {
                                           return (
                                               <div className="textMainCard">
                                                   {descriptions.name}
                                               </div>
                                           )
                                       })}</div>
                                       <button onClick={()=> {setModalActiveEditProductDescriptions(true)}}>ред. описание</button>
                                       <div className="textMainCardTitle">Компоненты:</div>
                                       <div>
                                           {productEdit.components.map((component, index)=> {
                                               return (
                                                   <div>
                                                       <tbody>
                                                       <tr>
                                                           <td style={{width:"550px"}}>{component.name} Кол-во:{component.count}шт. Цена:{component.price}руб. Итого:{component.sum}</td>
                                                           <td><button onClick={()=>{
                                                               modalEditProductDelComponent(component.name)
                                                           }}>-</button></td>
                                                       </tr>
                                                       </tbody>
                                                   </div>
                                               )
                                           })}
                                           <button onClick={()=> {
                                               setModalActiveEditProductComponents(true)
                                           }}>Добавить компонент</button>
                                       </div>
                                       Цена продукта: {productEdit.price}
                                   </div>
                               )
                            })}
                            {componentModalResult.map((component, idx)=>{
                                return (
                                    <div>Название:{component.name} Кол-во: {component.count}</div>
                                )
                            })}
                            <button style={{marginLeft:"450px"}} onClick={() => {
                                saveEditProduct(productEditOne)
                                setSearchName("")
                                setSelectedOptions(null)
                                setSelectedCategories(null)
                                setModelCreatProduct(false)
                                activeLoaderMainModal()
                            }
                            }>сохранить и изменить</button>
                        </Modal>
                        <Model active={modelCreatProduct} setActive={setModelCreatProduct}>
                            <div className="titleNewProductModal">Создание продукта</div>
                            <div className="titleNewProduct">Название продукта</div>
                            <input
                                className="inputCard1"
                                name="text"
                                placeholder={"Название букета "}
                                value={searchNameProductModal}
                                onChange={(event) => setSearchNameProductModal(event.target.value)}
                            />
                            <div>
                                <div className="titleNewProduct">Выберете категорию</div>
                                <div>
                                    <Select
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        onChange={(options) => {
                                            if (Array.isArray(options)) {
                                                options.map((opt) => opt)
                                                setSelectedCategories(options);
                                            }
                                        }}
                                        options={category}
                                        components={{
                                            Option: InputOption
                                        }}
                                    />
                                </div>
                                <div className="titleNewProduct">Выберете основную ОДНУ категории</div>
                                <div>
                                    <Select
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        onChange={(options) => {
                                            if (Array.isArray(options)) {
                                                options.map((opt) => opt)
                                                setSelectedCategoriesMain(options);
                                            }
                                        }}
                                        options={category}
                                        components={{
                                            Option: InputOption
                                        }}
                                    />
                                </div>

                            </div>
                            <div className="titleNewProduct">Выберете метки</div>
                            <div>
                                <Select
                                    type='checkbox' defaultChecked
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    onChange={(options) => {
                                        if (Array.isArray(options)) {
                                            setSelectedOptions(options);
                                        }
                                    }}
                                    options={tags}
                                    components={{
                                        Option: InputOption
                                    }}
                                />
                            </div>
                            <div>Добавте описание</div>
                            <select key={1} onChange={(e)=>setInputSelect(e.target.value)}>
                                <option value={"Категория 1"}>Категория 1</option>
                                <option value={"Категория 2"}>Категория 2</option>
                                <option value={"Категория 3"}>Категория 3</option>
                                <option value={"Категория 4"}>Категория 4</option>
                            </select>
                            <div className="titleNewProduct">Добавте компоненты</div>
                            <div>
                                <button onClick={()=>{setModalActiveNewComponentIsConstant(true)}
                                }>добавить компонент</button>
                            </div>
                            {componentModalResult.map((component, idx)=>{
                                return (
                                    <div>Название:{component.name} Кол-во: {component.count}</div>
                                )
                            })}
                            <button onClick={() => {
                                saveNewProduct  (
                                    searchNameProductModal,
                                    selectedCategoriesMain,
                                    selectedCategories,
                                    selectedOptions,
                                    componentModalResult,
                                    inputSelect,
                                )
                                setSearchName("")
                                setSelectedOptions(null)
                                setSelectedCategories(null)
                                setModelCreatProduct(false)
                            }
                            }>сохранить и создать</button>
                        </Model>

                        <Modal active={modalActiveEditProductName} setActive={setModalActiveEditProductName}>
                            <div>Редактирование имени продукта</div>
                            {productEditOne.map((product, indexProduct)=> {
                                return(
                                    <>
                                        <div>Текущее имя: {product.name}</div>
                                        <input
                                            className="inputCard1"
                                            name="text"
                                            placeholder={"Название букета "}
                                            value={searchName}
                                            onChange={(event) => setSearchName(event.target.value)}
                                        />
                                        <ul className="autoComplete">
                                            {
                                                searchName
                                                    ? filterNameProduct.map((product, index)=> {
                                                        return  (
                                                            <li
                                                                key={index}
                                                                className="autoComplete__item">
                                                                {product.name}
                                                            </li>
                                                        )
                                                    })
                                                    : null
                                            }
                                        </ul>
                                        <button onClick={()=>{
                                            modalEditProductSaveName(searchName,product.name)
                                            setModalActiveEditProductName(false)
                                        }}>Сохранить</button>
                                    </>
                                )
                            })}

                        </Modal>
                        <Modal active={modalActiveEditProductCategoryMain} setActive={setModalActiveEditProductCategoryMain}>
                            <div>
                                <div className="titleNewProduct">Редактирование категории и меток</div>
                                <div>
                                    {productEditOne.map((product, indexProduct)=> {
                                        return (
                                            <div>
                                                <div>Текущие сатегории:</div>
                                                {product.categories.map((categoryMain, indexCategory)=>{
                                                    return (
                                                        <div className="textMainCard">
                                                            {categoryMain.label}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )
                                    })}
                                    <Select
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        placeholder={"Выберете новые категории"}
                                        onChange={(options) => {
                                            if (Array.isArray(options)) {
                                                options.map((opt) => opt)
                                                setSelectedCategories(options);
                                            }
                                        }}
                                        options={category}
                                        components={{
                                            Option: InputOption
                                        }}
                                    />
                                </div>
                                <div className="titleNewProduct">Выберете основную ОДНУ категории</div>
                                <div>
                                    <div>Текущая основная сатегория:</div>
                                    {productEditOne.map((product, indexProduct)=> {
                                        return (
                                            <div className="textMainCard">{product.categoryMain}</div>
                                        )
                                    })}
                                    <Select
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        placeholder="Выберите одну основную категорию"
                                        onChange={(options) => {
                                            if (Array.isArray(options)) {
                                                options.map((opt) => opt)
                                                setSelectedCategoriesMain(options);
                                            }
                                        }}
                                        options={category}
                                        components={{
                                            Option: InputOption
                                        }}
                                    />
                                </div>

                            </div>
                            <div className="titleNewProduct">Выберете метки</div>
                            <div>
                                <div>Текущие метки:</div>
                                {productEditOne.map((product, indexProduct)=> {
                                    return (
                                        <div>
                                            {product.tags.map((tags, index)=>{
                                                return (
                                                    <div className="textMainCard">
                                                        {tags.label}
                                                    </div>
                                                )

                                            })}
                                        </div>
                                    )
                                })}
                                <Select
                                    type='checkbox' defaultChecked
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    onChange={(options) => {
                                        if (Array.isArray(options)) {
                                            setSelectedOptions(options);
                                        }
                                    }}
                                    options={tags}
                                    components={{
                                        Option: InputOption
                                    }}
                                />
                            </div>
                            <button onClick={()=>{
                                modalActiveEditProductCategoryMainAndTage()
                                setModalActiveEditProductCategoryMain(false)
                            }}>Сохранить</button>

                        </Modal>
                        <Modal active={modalActiveEditProductCategory} setActive={setModalActiveEditProductCategory}>
                            <div>Редактирование дополнительных категорий</div>
                        </Modal>
                        <Modal active={modalActiveEditProductTags} setActive={setModalActiveEditProductTags}>
                            <div>Редактирование меток</div>
                        </Modal>
                        <Modal active={modalActiveEditProductDescriptions} setActive={setModalActiveEditProductDescriptions}>
                            <div>Редактирование описания</div>
                            <div className="textMainCardTitle">Все описание:</div>
                            {description.map((descriptions, index)=>{
                                return(
                                    <div>
                                        <div>
                                            {descriptions.title}
                                        </div>
                                        <div style={{fontSize:"11px"}}>
                                            {descriptions.name}
                                        </div>
                                    </div>
                                )
                            })}
                            <div className="textMainCardTitle">Текущие описание:</div>
                            {productEditOne.map((product, index)=>{
                                return (
                                    <div>
                                        {product.descriptions.map((des, index)=> {
                                            return (
                                                <div>
                                                    {des.title}
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                            <div className="textMainCardTitle">Выберете новую категорию:</div>
                            <select key={1} onChange={(e)=>setInputSelect(e.target.value)}>
                                <option value={"Категория 1"}>Категория 1</option>
                                <option value={"Категория 2"}>Категория 2</option>
                                <option value={"Категория 3"}>Категория 3</option>
                                <option value={"Категория 4"}>Категория 4</option>
                            </select>
                            <button onClick={()=>{
                                modalEditProductSaveDes()
                                setModalActiveEditProductDescriptions(false)
                            }}>сохранить</button>
                        </Modal>
                        <Modal active={modalActiveEditProductComponents} setActive={setModalActiveEditProductComponents}>
                            <div>Добавить компонентов</div>
                            <input
                                    className="inputCard1"
                                    name="text"
                                    placeholder={"Название "}
                                    onClick={inputClickHeader}
                                    value={searchName}
                                    onChange={(event) => setSearchName(event.target.value)}
                                />
                                <ul className="autoComplete">
                                    {
                                        searchName && isOpen
                                            ? filterName.map((component, index)=> {
                                                return  (
                                                    <li
                                                        key={index}
                                                        onClick={itemClickHandler}
                                                        className="autoComplete__item">
                                                        {component.name}
                                                    </li>
                                                )
                                            })
                                            : null
                                    }
                                </ul>
                            <div>
                                <input
                                    disabled={false}
                                    placeholder={"Колличество"}
                                    value={input}
                                    onChange={(e) => {
                                        setInput(e.target.value)
                                    }}/>
                                <div>
                                    <button
                                        onClick={()=> {
                                            modalAddComponent(input,searchName)
                                            setModalActiveEditProductComponents(false)
                                        }}>Добавить</button>
                                </div>
                            </div>

                        </Modal>

                        <Modal active={modalActiveNewComponent} setActive={setModalActiveNewComponent}>
                            <div>
                                <div>Добавить компонент в букет</div>
                                <input
                                    className="inputCard1"
                                    name="text"
                                    placeholder={"Название "}
                                    onClick={inputClickHeader}
                                    value={searchName}
                                    onChange={(event) => setSearchName(event.target.value)}
                                />
                                <ul className="autoComplete">
                                    {
                                        searchName && isOpen
                                            ? filterName.map((component, index)=> {
                                                return  (
                                                    <li
                                                        key={index}
                                                        onClick={itemClickHandler}
                                                        className="autoComplete__item">
                                                        {component.name}
                                                    </li>
                                                )
                                            })
                                            : null
                                    }
                                </ul>
                            </div>
                            <input
                                disabled={false}
                                placeholder={"Колличество"}
                                value={input}
                                onChange={(e) => {
                                    setInput(e.target.value)
                                }}/>
                            <div>
                                <button
                                    onClick={()=> {
                                        addComponentInBouquet(input,searchName,product.price)

                                    }}>Добавить</button>
                            </div>
                        </Modal>
                        <Modal active={modalActiveNewComponentIsConstant} setActive={setModalActiveNewComponentIsConstant}>
                            <div>
                                <div>Добавить компонент в букет</div>
                                <input
                                    className="inputCard1"
                                    name="text"
                                    placeholder={"Название "}
                                    onClick={inputClickHeaderComponent}
                                    value={searchNameComponent}
                                    onChange={(event) => setSearchNameComponent(event.target.value)}
                                />
                                <ul className="autoComplete">
                                    {
                                        searchNameComponent && isOpen
                                            ? filterNameComponent.map((component, index)=> {
                                                return  (
                                                    <li
                                                        key={index}
                                                        onClick={itemClickHandlerComponent}
                                                        className="autoComplete__item">
                                                        {component.name}
                                                    </li>
                                                )
                                            })
                                            : null
                                    }
                                </ul>
                            </div>
                            <input
                                disabled={false}
                                placeholder={"Колличество"}
                                value={inputComponent}
                                onChange={(e) => {
                                    setInputComponent(e.target.value)
                                }}/>
                            <div>

                                <button
                                    onClick={()=> {
                                        const componentModal = {
                                            name: searchNameComponent,
                                            count: inputComponent
                                        }

                                        const res = [...componentModalResult, componentModal]
                                        console.log("res")
                                        console.log(res)
                                        setComponentModalResult(res)
                                        setInputComponent("")
                                        setSearchNameComponent("")
                                        setModalActiveNewComponentIsConstant(false)
                                        //componentResult(componentModal)
                                    }}>Добавить</button>
                            </div>
                        </Modal>
                        <Modal active={modalActiveConstant} setActive={setModalActiveConstant}>
                            <h2>Добавить компонент в прайс</h2>
                            <div>
                                <input
                                    className="inputCard1"
                                    name="text"
                                    placeholder={"Название "}
                                    onClick={inputClickHeader}
                                    value={searchName}
                                    onChange={(event) => setSearchName(event.target.value)}
                                />
                                <ul className="autoComplete">
                                    {
                                        searchName && isOpen
                                            ? filterName.map((component, index)=> {
                                                return  (
                                                    <li
                                                        key={index}
                                                        onClick={itemClickHandler}
                                                        className="autoComplete__item">
                                                        {component.name}
                                                    </li>
                                                )
                                            })
                                            : null
                                    }
                                </ul>
                            </div>
                            <input
                                disabled={false}
                                placeholder={"Цена"}
                                value={input}
                                onChange={(e) => {
                                    setInput(e.target.value)
                                }}/>
                            <div>
                                <button
                                    onClick={()=>addConstant(input, searchName)}>Добавить</button>
                            </div>
                        </Modal>
                        <Modal active={modalActive} setActive={setModalActive}>
                            <h2>Редактирование колличества</h2>
                            <h3>"Название букета:" {productNameInput}</h3>
                            <h3>"WooId букета:" {productIdWoo}</h3>
                            <h4>"Название компонента:" {componentNameInput} Кол-во: {componentCountInput}</h4>
                            <input
                                disabled={false}
                                placeholder={"Введите новое значение"}
                                value={input}
                                onChange={(e) => {
                                    setInput(e.target.value)
                                }}/>
                            <div>
                                <button
                                    onClick={()=>editIngredientOne(
                                        productIdWoo,
                                        componentNameInput,
                                        input,
                                        indexComponent)
                                    }>Сохранить</button>
                            </div>
                        </Modal>
                        <Modal active={modalEditPrice} setActive={setModalEditPrice}>
                            <h2>Массовое изменение цены</h2>
                            <div>
                                <input
                                    className="inputCard1"
                                    name="text"
                                    placeholder={"Название "}
                                    onClick={inputClickHeader}
                                    value={searchName}
                                    onChange={(event) => setSearchName(event.target.value)}
                                />
                                <ul className="autoComplete">
                                    {
                                        searchName && isOpen
                                            ? filterName.map((component, index)=> {
                                                return  (
                                                    <>
                                                        <li
                                                            key={index}
                                                            onClick={()=>itemClickHandler1(component.name)}
                                                            className="autoComplete__item">
                                                            {`${component.name} Цена: ${component.price}`}
                                                        </li>
                                                    </>
                                                )
                                            })
                                            : null
                                    }
                                </ul>
                            </div>
                            <input
                                disabled={false}
                                placeholder={"Новая цена"}
                                value={input}
                                onChange={(e) => {
                                    setInput(e.target.value)
                                }}/>
                            <div>
                                <button
                                    onClick={()=>editIngredientAll(
                                        input, searchName)
                                    }>Изменить</button>
                            </div>
                        </Modal>
                        <Modal active={modalAddDescriptionAndMainCategories} setActive={setModalAddDescriptionAndMainCategories}>

                            <div className="titleNewProduct">Выберете описание</div>
                            <div>
                                <select key={1} value={productDescription} onChange={(e)=>setProductDescription(Number(e.target.value))}>
                                    <option value={0}>Простой букет</option>
                                    <option value={1}>Съедобный букет</option>
                                    <option value={2}>Алкогольный Букет</option>
                                </select>
                            </div>
                            <div className="titleNewProduct">Выберете основную категорию</div>
                            <div>
                                <select key={1} value={selectedCategories} onChange={(e)=>setSelectedCategories(e.target.value)}>
                                    {/*{options.map((options) => (*/}
                                    {/*    <option value={options.id}>{options.label}</option>*/}
                                    {/*))}*/}
                                </select>
                            </div>
                            {/*<div><button onClick={addDescriptionAndMainCategories}>Сохранить</button></div>*/}
                        </Modal>
                        {/*<button onClick={createProDB}>Создать букет DB</button>*/}
                        {/*<button onClick={createProDBWoo}>Создать продукт на прямую из Woo</button>*/}
                        {/*<button onClick={delFullDB}>Удаляет полностью нашу базу</button>*/}

                        {/*<button onClick={createConstDB}>Создать константы DB</button>*/}

                        {/*<button className="btmMain" onClick={()=>{getProd(); activeLoaderMain()}}>Получить букеты с нашей базы</button>*/}
                        {/*<button onClick={goModalConstant}>Добавить компонент в прайс</button>*/}
                        {/*<button onClick={goModalEditPrice}>Массовое изменение цены</button>*/}
                        {/*<button className="btmMain" onClick={()=>{createPriceComponentWait(); activeLoaderMain()}}>Проставить цены</button>*/}
                        {/*<button onClick={()=>deleteAll()}>del</button>*/}
                        {/*<button className="btmMain" onClick={()=>{sentWooWait(); activeLoaderMain()}}>Отправить в Woo(массовое изменине цены)</button>*/}
                        <div>
                            <button className="btmMainCreate" style={{marginTop:"50px"}} onClick={creatNewProduct}>Создать продукт</button>
                            {/*<button onClick={time}>Временная функция</button>*/}
                        </div>
                        <div>
                            <input
                                className="inputCard1"
                                name="text"
                                placeholder={"Поиск "}
                                value={searchNameProduct}
                                onChange={(event) => setSearchNameProduct(event.target.value)}
                            />
                            <button className="btmMain" onClick={()=> {
                                setFilter(false);
                                //activeLoaderMain()
                                clickCountWait()
                            }}>
                                сортировка с первого/последнего
                            </button>
                            <button className="btmMain" onClick={()=>{
                                setFilter(true)
                                //activeLoaderMain()
                            }}>
                                сортировка по алфавиту
                            </button>
                        </div>
                        <Pagination className="pagMain" count={Math.round((product.length/10)+1)} page={page} onChange={handleChange} />
                        <div>Общее количество product: {product.length} шт.</div>
                        {citrus.map((product, indexProduct) => {
                            return (
                                <tbody>
                                <tr>
                                    <td> <div className="mainCard" key={indexProduct}>
                                        <div>
                                            <button className="btmDelete"onClick={()=> {
                                                    productEditOne.length = 0
                                                    const clone = Object.assign([], product);
                                                    productEditOne.push(clone)
                                                    console.log("productEditOne")
                                                    console.log(productEditOne)
                                                    console.log("productEditOneComponents")
                                                    console.log(productEditOne[0].components)
                                                editProductCard (
                                                    product,
                                                    product.id,
                                                    product.idWoo,
                                                    product.name,
                                                    product.categoryMain,
                                                    product.imgUrl,
                                                    product.components,
                                                    product.categories,
                                                    product.tags
                                                )
                                            }}>редактировать</button>
                                            <button className="btmEdit" onClick={()=>{deleteProduct(product.idWoo)}}>удалить</button>
                                        </div>
                                        <img className="imgCard" src={product.imgUrl} alt="логотип" />
                                        <div>Название: {product.name || null}</div>
                                        {/*<div>№ п/п: {product.id}</div>*/}
                                        <div>idWoo: {product.idWoo}</div>
                                        <div>Артикул: {product.vendorСode}</div>
                                        <div><a href={product.url} target="_blank">{product.url}</a></div>
                                        <div className="textMainCardTitle">Основная категория:</div>
                                        <div className="textMainCard">{product.categoryMain || null}</div>
                                        <div className="textMainCardTitle">Дополнительные категории:</div>
                                        {product.categories.map((category, index) => {
                                        return(
                                                <div className="textMainCard">
                                                    {category.label}
                                                </div>

                                        )})}
                                        <div className="textMainCardTitle">Метки:</div>
                                        {product.tags.map((tag, index) => {
                                            return(
                                                <div className="textMainCard">
                                                    {tag.label}
                                                </div>

                                            )}) || undefined}
                                        <div className="textMainCardTitle">Описание:</div>
                                        {product.descriptions.map((description, index) => {
                                            return(
                                                <div className="textMainCard">
                                                    {description.title}
                                                </div>

                                            )})}
                                        {product.components.map((component, indexComponent) => {
                                            return (
                                                <div className="btnСard" key={indexComponent}>
                                                    <input style={{width:"350px"}} id={indexComponent} disabled={true} name={indexComponent} placeholder={component.name} />
                                                    <input disabled={true} className="inputCount2" placeholder={`${component.count}шт`}/>
                                                    <input disabled={true} className="inputCount2" placeholder={`${component.price}руб`}/>
                                                    <input disabled={true} className="inputCount2" placeholder={`${component.sum}руб`}/>
                                                </div>
                                            )}) || null}
                                        <div>
                                            Итого: {product.price || null}
                                        </div>
                                    </div></td>
                                    <td><div>

                                    </div></td>
                                </tr>
                                <button onClick={()=>{photoSend(product.idWoo)}}>обновить для фото</button>
                                </tbody>
                            )})}
                        <Pagination className="pagMain" count={Math.round((product.length/10)+1)} page={page} onChange={handleChange} />
                    </div>
                </Typography>
            </Stack>
        </>
    )
}
export default HomePage;

