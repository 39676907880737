import {$host} from "../http/index";

export const createProductDB = async (product) => {
    console.log(product)
    for (let i = 0; i < product.length; i++) {
        await $host.post('api/product/createProductAllDb', product[i])
        console.log(product[i])
    }
}

export const getProduct = async (product) => {
    const {data} = await $host.get('api/product', product)
    return data
}

export const getProductOneDB = async (product) => {
    console.log(product)
    const {data} = await $host.get('api/product/getProductOneDB', product)
    console.log(data)
    return data
}

export const createProductOne = async (product) => {
    //console.log("product")
    //console.log(product)
    const {data} = await $host.post('api/product/createProductOne', product)
    //console.log(data)
    return data
}

export const updateProductOne = async (product) => {
    console.log(product)
    const {data} = await $host.put('api/product/updateProductOne', product)
    //console.log(data)
    return data
}

// export const delComponent = async (product) => {
//     const {data} = await $host.put('api/product/delComponent', product)
//     return data
// }

export const delProduct = async (product) => {
    const {data} = await $host.put('api/product/delProduct', product)
    return data
}

export const deleteFullDB = async () => {
    await $host.delete('api/product/deleteFullDB')
    return
}

export const addComponent = async (product) => {
    // console.log("product")
    // console.log(product)
    const {data} = await $host.put('api/product/addComponent', product)
    //console.log(data)
    return data
}
export const updateProduct = async (product) => {
    //console.log(`API product update ${product}`)
    const {data} = await $host.put('api/product', product)
    //console.log(`API data delete update ${data}`)
    return data
}
export const updateProductPhoto = async (product) => {
    const {data} = await $host.put('api/product/updateProductPhoto', product)
    return data
}

export const updateProductAll = async (product) => {
    //console.log(`API updateProductAll update ${product}`)
    const {data} = await $host.put('api/product/updateProductAll', product)
    //console.log(`API data delete update ${data}`)
    return data
}

export const deleteALLTable = async (product) => {
    //console.log("Click http")
    const {data} = await $host.delete('api/product/deleteALLTable', product)
    return data
}

export const creatNewProductNoComponent = async (product) => {
    //console.log(product)
    for (let i = 0; i < product.length; i++) {
        await $host.post('api/product/creatNewProductNoComponent', product[i])
        //console.log(product[i])
    }
}


