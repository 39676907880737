import {$host} from "../http/index";


export const createDescriptionDB = async (description) => {
    for (let i = 0; i < description.length; i++) {
        await $host.post('api/description', description[i])
        //console.log(description[i])
        // return data
    }
}

export const getDescription = async (description) => {
    const {data} = await $host.get('api/description', description)
    //console.log(data)
    return data
}


export const createDescription = async (description) => {
    //console.log(description)
    const {data} = await $host.put('api/description/newDescription', description)
    //console.log(data)
    return data
}



export const getDescriptionOne = async (description) => {
    const {data} = await $host.get('api/description/one', description)
    return data
}

export const updateDescription = async (description) => {
    const {data} = await $host.put('api/description/updateDescription', description)
    //console.log(description)
    return data
}


export const delDescriptionPrice = async (description) => {
    const {data} = await $host.put('api/description/delDescriptionPrice', description)
    //console.log(description)
    return data
}
